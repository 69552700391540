@font-face {
    font-family: AntDesign;
    font-style: normal;
    font-weight: 400;
    src: url(fonts/AntDesign.ttf) format('truetype')
}

@font-face {
    font-family: Entypo;
    font-style: normal;
    font-weight: 400;
    src: url(fonts/Entypo.ttf) format('truetype')
}

@font-face {
    font-family: EvilIcons;
    font-style: normal;
    font-weight: 400;
    src: url(fonts/EvilIcons.ttf) format('truetype')
}

@font-face {
    font-family: Feather;
    font-style: normal;
    font-weight: 400;
    src: url(fonts/Feather.ttf) format('truetype')
}

@font-face {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    src: url(fonts/FontAwesome.ttf) format('truetype')
}

@font-face {
    font-family: Foundation;
    font-style: normal;
    font-weight: 400;
    src: url(fonts/Foundation.ttf) format('truetype')
}

@font-face {
    font-family: Ionicons;
    font-style: normal;
    font-weight: 400;
    src: url(fonts/Ionicons.ttf) format('truetype')
}

@font-face {
    font-family: MaterialCommunityIcons;
    font-style: normal;
    font-weight: 400;
    src: url(fonts/MaterialCommunityIcons.ttf) format('truetype')
}

@font-face {
    font-family: MaterialIcons;
    font-style: normal;
    font-weight: 400;
    src: url(fonts/MaterialIcons.ttf) format('truetype')
}

@font-face {
    font-family: Octicons;
    font-style: normal;
    font-weight: 400;
    src: url(fonts/Octicons.ttf) format('truetype')
}

@font-face {
    font-family: SimpleLineIcons;
    font-style: normal;
    font-weight: 400;
    src: url(fonts/SimpleLineIcons.ttf) format('truetype')
}

@font-face {
    font-family: Zocial;
    font-style: normal;
    font-weight: 400;
    src: url(fonts/Zocial.ttf) format('truetype')
}
.App {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  text-align: center;
}

.App-logo {
  height: calc(60px + 2vmin);
  width: calc(60px + 2vmin);
  pointer-events: none;
}

.avatar {
  height: calc(60px + 2vmin);
  width: calc(60px + 2vmin);
  pointer-events: none;
  border-radius: 50px;
  margin-right: 20px;
  background-size: 100%;
}

.App-header {
  background-color:  #fff5;
  height: calc(60px + 2vmin);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 1vmin;
  padding-left: 20px;
  box-shadow: 0 3px 3px #fff5;
}

.Menu {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

}
.menuIcon {
 display: none;
 height: calc(60px + 2vmin);
  width: calc(60px + 2vmin);
  pointer-events: none;
  border-radius: 50px;
  margin-right: 20px;
  background-size: 100%;
}
.menuItem {
  position: relative;
  z-index: 110;
  flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;  
  margin-right: 20px;
  margin-left: 20px;
  height: calc(60px + 2vmin);
  transition: ease 1s;
}

.menuItemText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; 
  text-align: center;
  font-size: calc(12px + 1vmin);
  color: #fffd;
  width: 100%;
  height: 100%;
}


.menuItemDiv {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;  
  height: 100%;
  width: 100%;
}

.menuItem:hover {
  cursor: pointer;
  background-color: #1b1464;
  box-shadow: 0 0 8px #1b1464de;
  transition: ease 1s;
}

.menuItemText:hover {
  color: #fcee21;
}
/* 
.menuItemText:hover+ .dropDownMenu{
height: auto;
transition: 1s;
}
 
.dropDownMenuSlideDiv {
  background-color: transparent;
  width: 100%;
  border-radius: 8px;
  position: absolute;
  z-index: 101;
  left: 0px;
  top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end; 
  overflow: hidden;
}
*/
.dropDownMenu {
  background-color: #1b1464;
  box-shadow: 0 0 8px #1b1464de;
  width: calc(100% - 1px);
  border-radius: 8px;
  position: absolute;
  z-index: 100;
  left: 0px;
  top: 0px;
  top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end; /* 
  overflow: hidden;
  transition: max-height 1s; */
}


.App-body {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  height: calc(100vh - (120px + 2vmin));
}

.App-filters {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding: 20px;
}

.lost {
  margin: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 128px;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
}

.lostItem {
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fffd;
  background-color: #1119;

}

.lostItem:hover {
  cursor: pointer;
}

.kindFilter, .sexFilter {
  margin: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
}

.App-data {
  flex: 4;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow-y: auto;
  flex-wrap: wrap;
}

.cartPet {
  position: relative;
  margin: 10px;
  height: 200px;
  width: 320px;
  border-radius: 15px;
  background-size: 100%;
}

.cartPetSex {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 15px;
  background-color: #282c349d;
  display: flex;
  align-items: center;
  justify-content: center;
}
.App-footer {
  position: relative;
  background-color: #0f0a41;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(12px + 2vmin);
  color: white;
  padding: 20px;
  box-shadow: 0 -5px 5px #0f0a4188;
}



.App-link {
  color: #61dafb;
}

/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: calc(18px + 1vmin);
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  /* padding: 0.8em; */
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding-bottom: 2vmin;
  padding-top: 2vmin;
}

/* Styling of overlay */
.bm-overlay {
  background: #0f0a4188;
}

.menu-item-small-red {
 color: #e9b9b9
}

.menu-item-small-green {
color: #b9e9cd
}

.menu-item-small-blue{
color: #b9bce9
}

@media screen and (max-width: 480px) {

.Menu {
  display: none;
}

.App-body {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;
  height: calc(100vh - (120px + 2vmin));
}

.App-data {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow-y: auto;
  flex-wrap: nowrap;
}

.menuIcon {
 display: flex;
}

.cartPet {
  margin: 0px;
  margin-bottom: 10px;
  height: 100px;
  width: 100%;
  min-width: 160px;
}
.bm-burger-button {
  display: flex;
}
}
/* 
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}
 */
